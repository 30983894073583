import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ReadingScene = (useMarkers) => {
  const reading = document.getElementById('reading');
  const readingText = document.getElementById('readingText');
  const readingAppView = document.getElementById('readingAppView');

  const phoneViewVideo = document.getElementById('phoneViewVideo');
  phoneViewVideo.pause();

  const readingBgCover1 = document.getElementById('readingBgCover1');
  const readingBgCover2 = document.getElementById('readingBgCover2');
  const readingBgCover3 = document.getElementById('readingBgCover3');
  const readingBgCover4 = document.getElementById('readingBgCover4');
  const readingBgCover5 = document.getElementById('readingBgCover5');
  const readingBgCover6 = document.getElementById('readingBgCover6');
  const readingBgCover7 = document.getElementById('readingBgCover7');

  gsap.fromTo(
    reading,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        markers: useMarkers,
        scrub: true,
        trigger: reading,
        start: () => 'top-=100 center',
        end: () => 'top+=50 center',
        onEnter: () => {
          phoneViewVideo.load();
        },
      },
    }
  );

  let readingAppViewMoveOffset = readingAppView.offsetHeight;

  window.addEventListener('resize', () => {
    readingAppViewMoveOffset = readingAppView.offsetHeight;
  });

  gsap.fromTo(
    readingAppView,
    { scale: 1, transform: 'translate3d(0, 0, 0)' },
    {
      scale: 0.8,
      transform: `translate3d(0, ${readingAppViewMoveOffset * -0.25}px , 0)`, // TODO: calculate an ideal value
      scrollTrigger: {
        markers: useMarkers,
        scrub: true,
        trigger: readingText,
        start: () => 'bottom center',
        end: () => 'bottom+=150 center',
        pin: true,
        anticipatePin: 1,
      },
    }
  );

  gsap.fromTo(
    readingText,
    { opacity: 1 },
    {
      opacity: 0,
      scrollTrigger: {
        markers: useMarkers,
        scrub: true,
        trigger: readingText,
        start: () => 'bottom center',
        end: () => 'bottom+=100 center',
        pin: true,
      },
    }
  );

  gsap.fromTo(
    readingAppView,
    {},
    {
      scrollTrigger: {
        scrub: true,
        trigger: readingAppView,
        markers: useMarkers,
        start: () => 'top+=100 center',
        end: () => 'bottom center',
        onEnter: () => {
          phoneViewVideo.play();
        },
      },
    }
  );

  gsap.fromTo(
    readingBgCover1,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        scrub: true,
        trigger: readingAppView,
        markers: useMarkers,
        start: () => 'top+=150 center',
        end: () => 'top+=180 center',
      },
    }
  );

  gsap.fromTo(
    readingBgCover6,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        scrub: true,
        trigger: readingAppView,
        markers: useMarkers,
        start: () => 'top+=160 center',
        end: () => 'top+=190 center',
      },
    }
  );

  gsap.fromTo(
    readingBgCover2,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        scrub: true,
        trigger: readingAppView,
        markers: useMarkers,
        start: () => 'top+=180 center',
        end: () => 'top+=210 center',
      },
    }
  );

  gsap.fromTo(
    readingBgCover5,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        scrub: true,
        trigger: readingAppView,
        markers: useMarkers,
        start: () => 'top+=200 center',
        end: () => 'top+=230 center',
      },
    }
  );

  gsap.fromTo(
    readingBgCover4,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        scrub: true,
        trigger: readingAppView,
        markers: useMarkers,
        start: () => 'top+=210 center',
        end: () => 'top+=240 center',
      },
    }
  );

  gsap.fromTo(
    readingBgCover3,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        scrub: true,
        trigger: readingAppView,
        markers: useMarkers,
        start: () => 'top+=230 center',
        end: () => 'top+=270 center',
      },
    }
  );

  gsap.fromTo(
    readingBgCover7,
    { opacity: 0 },
    {
      opacity: 1,
      scrollTrigger: {
        scrub: true,
        trigger: readingAppView,
        markers: useMarkers,
        start: () => 'top+=260 center',
        end: () => 'top+=300 center',
      },
    }
  );
};

export default ReadingScene;
